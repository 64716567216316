import $ from 'jquery';
import '../scss/sticky-ag.scss';
require('floatthead');

$(document).ready(function () {
    // console.log('sticky ag');
    var $container = $('.ag_content');
    var $header = $('#header .headerbuilder');
    var $table = $('table.io_table_1');

    function isHeaderSticky() {
        return $header.hasClass('sticky');
    }

    function getHeaderHeight() {
        if($(window).width()>=992){
            // console.log($header.height());
            return $header.height();
        }
        else{
            return 0;
        }
    }

    function getHeaderHeight2(sticky) {
        if($(window).width()>=992){
            // console.log($header.height());
            return $header.height();
        }
        else{
            return 0;
        }
    }



    if ($table.length < 1) {
        console.log('ag not found');
    } else {
        console.log('ag found');

        let currentWidth = $(window).width();
        let sticky = false;
        if(currentWidth>992){
            sticky = true;
        }
        $table.floatThead({
            position: 'absolute',
            autoReflow: true,
            debug: true,
            top: getHeaderHeight(sticky)
        });
    }
});

